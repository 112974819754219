<template>
  <main>
    <section>
      <article class="about_us_article_one">
        <img src="../assets/big_images/pool1.jpg">
        <div class="about_us_article_one_description">
          <div class="container">
            <div class="row">
              <div class="col-md-9">
                <h1>Explore the unbeaten track, stay with us!</h1>
                <p class="mt-4">We love to travel and we want to share our excitement with you!</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_two pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <p
                class="italic-text"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Experience all the Pearl of Africa has to offer as you tour through our cities,
                National Parks, and Wildlife Reserves.
              </p>
            </div>
            <div class="col-md-8">
              <h2
                data-aos="fade-up"
                data-aos-duration="3000"
              >Adere Safari Lodges</h2>
              <p
                data-aos="fade-up"
                data-aos-duration="3000"
                style="font-size: 1.4em;"
              >
                Adere Safari Lodge boasts of hand-made architecture by local craftsmen and comprises 20 en-suite rooms; an intimate African themed conference hall, a boardroom, a restaurant with jaw dropping views, a well stocked bar, and magniﬁcent gardens overlooking Kidepo Valley National Park. Adere Safari Lodge takes pride in its swimming pool carved out of big rocks.
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="about_us_article_three pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_three_grid">
            <div>
              <div>
                <!-- <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium</h2> -->
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Adere, loosely means Calabash, in Iteso a local language in Uganda.
                  A calabash is an artifact made from the hard shell of a fruit of the gourd family
                  which is dried and hollowed out for serving or storing food and other items.
                  Adere, therefore, symbolizes the life-enriching experience and care you will
                  get while at Lodge and this is what makes our world turn - ensuring that every precious
                  moment spent with us is meaningful, rich, and beautiful. A warm welcome to the amazing
                  Adere Safari Lodge.
                </p>
              </div>
            </div>
            <div>
              <img
                src="../assets/images/bg1.jpg"
                class="w-100"
              >
            </div>
          </div>
        </div>
      </article>
    </section>

    <!-- <section>
      <article class="about_us_article_four pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_four_grid">
            <div>
              <div class="text-center w-100 h-100">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >+500</h2>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Tourists per month</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >We have handled all types of clients from all parts of the world including: Adventure seekers, bird
                  watchers and Twitchers, Film crews, Humanitarians, and Honeymooners! </p>
              </div>
            </div>
            <div>
              <div class="text-center w-100 h-100">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >+4</h2>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                > Countries</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >We are the East African Tour specialists, have a unique safari in , Uganda, Kenya, Tanzania, Rwanda
                </p>
              </div>
            </div>
            <div>
              <div class="text-center w-100 h-100">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >+100</h2>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Travel packages</h3>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Our trips are offered on a full package basis that includes accommodation, park entries, vehicle, and
                  guide fees.</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section> -->

    <section>
      <article class="about_us_article_five pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_five_grid">
            <div>
              <img
                src="../assets/images/bg1.jpg"
                class="w-100"
              >
            </div>
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Who we are</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  You will love it here. We look forward to making your safari memorable, restful,
                  worthwhile and transformational. Whether it is a drive through the vast savannah of
                  Kidepo, a sunset dinner, a late night reading of a book, an early morning watch of
                  the sun as it rises, or a community walk and leisurely walk about, may your time at
                  Adere create beautiful Memories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>

    <!-- <section>
      <article class="about_us_article_three pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_three_grid">
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >What drives us</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  We believe that travel can be a positive influence in personal and community growth.
                  We consistently provide high-quality content, a superior user experience, and exceptional customer
                  care.
                  <br>
                  We offer you the security, efficiency, and peace-of-mind needed when booking
                  unforgettable holidays. Each travel experience is unique, and each individual involved
                  in creating that experience is unique. We embrace this diversity.
                </p>
              </div>
            </div>
            <div>
              <img
                src="../assets/images/bg1.jpg"
                class="w-100"
              >
            </div>
          </div>
        </div>
      </article>
    </section> -->

    <!-- <section>
      <article class="about_us_article_five pt-5 pb-5">
        <div class="container">
          <div class="about_us_article_five_grid">
            <div>
              <img
                src="../assets/images/bg1.jpg"
                class="w-100"
              >
            </div>
            <div>
              <div>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Tailor Made Tours</h2>
                <p
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Create your own dreams!</p>
                <p
                  style="color:black;"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Creating your own trip is the best way of getting everything out of your
                  holiday. The Access Uganda Tours staff is well known for being innovative and
                  creative. Use our specialism to create your own dream holiday!
                </p>
                <p
                  style="color:black;"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  Please fill out the form below and you'll receive our offer and itinerary
                  for a quality trip. You may expect a response within 24 hours on workdays
                  (Monday-Friday).
                </p>
                <el-button
                  style="background-color: #D96B12; color: white;"
                  @click="$router.push({ path: '/tailor-made-safaris' })"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >Create your own dream Safaris <i class="el-icon-d-arrow-right"></i></el-button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section> -->

  </main>
</template>

<script>
export default {};
</script>

<style scoped>
.about_us_article_one {
  background-color: #f0f9eb;
  height: 100vh;
  width: 100%;
  position: relative;
}

.about_us_article_one img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.about_us_article_one_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;
}

.about_us_article_one_description h1 {
  color: white;
  font-size: 2.2em;
  font-weight: 600;
}

.about_us_article_one_description p {
  color: white;
  font-size: 1.2em;
  font-weight: 500;
}

.about_us_article_two {
  background-color: #f0f9eb;
}

.about_us_article_two .italic-text {
  color: grey;
  font-size: 1.8em;
  font-weight: 300;
  font-style: italic;
}

.about_us_article_two h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_three,
.about_us_article_five {
  background-color: #f0f9eb;
}

.about_us_article_three .about_us_article_three_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.about_us_article_three .about_us_article_three_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.about_us_article_three .about_us_article_three_grid img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about_us_article_three .about_us_article_three_grid h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_three .about_us_article_three_grid p {
  color: grey;
  font-size: 1em;
}

.about_us_article_four {
  background-color: #ffffff;
}

.about_us_article_four .about_us_article_four_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.about_us_article_four .about_us_article_four_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_us_article_four .about_us_article_four_grid h2 {
  color: green;
  font-size: 2.8em;
  font-weight: 800;
}

.about_us_article_four .about_us_article_four_grid h3 {
  color: black;
  font-size: 1.2em;
  font-weight: 600;
}

.about_us_article_four .about_us_article_four_grid p {
  color: grey;
  font-size: 1em;
}

.about_us_article_five .about_us_article_five_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.about_us_article_five .about_us_article_five_grid > div {
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.about_us_article_five .about_us_article_five_grid img {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about_us_article_five .about_us_article_five_grid h2 {
  color: black;
  font-size: 2em;
  font-weight: 600;
}

.about_us_article_five .about_us_article_five_grid p {
  color: grey;
  font-size: 1em;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .about_us_article_three .about_us_article_three_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_three .about_us_article_three_grid > div:first-child {
    grid-auto-flow: row;
    grid-row: 2;
  }

  .about_us_article_three .about_us_article_three_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_three .about_us_article_three_grid p {
    font-size: 0.8em;
  }

  .about_us_article_five .about_us_article_five_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_five .about_us_article_five_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_five .about_us_article_five_grid p {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .about_us_article_four .about_us_article_four_grid h2 {
    font-size: 1.8em;
  }

  .about_us_article_four .about_us_article_four_grid h3 {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid p {
    display: none;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .about_us_article_three .about_us_article_three_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_three .about_us_article_three_grid > div:first-child {
    grid-auto-flow: row;
    grid-row: 2;
  }

  .about_us_article_three .about_us_article_three_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_three .about_us_article_three_grid p {
    font-size: 0.8em;
  }

  .about_us_article_five .about_us_article_five_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .about_us_article_five .about_us_article_five_grid h2 {
    font-size: 1.5em;
  }

  .about_us_article_five .about_us_article_five_grid p {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  .about_us_article_four .about_us_article_four_grid h2 {
    font-size: 1.8em;
  }

  .about_us_article_four .about_us_article_four_grid h3 {
    font-size: 0.8em;
  }

  .about_us_article_four .about_us_article_four_grid p {
    display: none;
  }
}
</style>